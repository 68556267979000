<template>
  <transition name="fade">
    <div v-if="$store.getters['page/isPageLoaded']">
      <div class="plan_setting">
        <ul class="list">
          <template v-if="!isNewPlan">
            <plan-item
              v-for="planItem in planItems"
              :key="planItem.id"
              :plan="planItem"
              :light-plan-items="lightPlanItems"
              :current-plan-id="plan.id"
              class="item"
              :class="getPlanDataById(plan.id).name"
              @openModalChangePlan="openModalChangePlan"
            />
          </template>
          <template v-if="isNewPlan">
            <new-plan-item
              v-for="planItem in planItems"
              :key="planItem.id"
              :plan="planItem"
              :light-plan-items="lightPlanItems"
              :current-plan-id="plan.id"
              class="item"
              :class="getPlanDataById(plan.id).name"
              @openModalChangePlan="openModalChangePlan"
            />
          </template>
        </ul>
        <modal-window
          :is-visible="changePlan.isVisible"
          :is-loading="isLoading"
          :message="`${changePlan.data.name_ja}の問い合わせをしますか？\n※お問合せ後、担当からご連絡いたします`"
          :data="changePlan.data"
          class="plan_modal"
          title="お問い合わせ"
          button-text="問い合わせを申請"
          @close="changePlan.isVisible = false"
          @click="sendChangePlanRequest"
        />
      </div>
      <p class="plan_title">その他の追加サービス</p>
      <div class="inquiry_setting">
        <ul class="list">
          <li v-for="menu in menuItems" :key="menu.id" class="item">
            <div class="menu_block">
              <div class="title">{{ menu.title }}</div>
              <div class="contents">{{ menu.content }}</div>
            </div>
            <div class="contact_block">
              <button class="btn" @click="openModalInquiry(menu)">
                {{ menu.btn_title }}
              </button>
            </div>
          </li>
        </ul>
        <modal-window
          :is-visible="inquiry.isVisible"
          :is-loading="isLoading"
          :message="`${inquiry.data.title}の問い合わせをしますか？\n※お問合せ後、担当からご連絡いたします`"
          :data="inquiry.data"
          class="plan_modal"
          title="お問い合わせ"
          button-text="問い合わせを申請"
          @close="inquiry.isVisible = false"
          @click="sendInquiryRequest"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { postPottosEvent } from '@/utils/pottos';

import {
  mochicaPlans,
  getPlanDataById,
  getIsNewPlanById,
  PLAN_IDS,
} from '@/defines/plan';
import planService from '@/services/plan';
import PlanItem from '@/components/page/configs/plan/components/PlanItem';
import NewPlanItem from '@/components/page/configs/plan/components/NewPlanItem';

export default defineComponent({
  name: 'Plan',
  components: { PlanItem, NewPlanItem},
  setup(props, context) {
    const store = useStore();
    const menuItems = ref([]);
    const planItems = ref([]);
    const lightPlanItems = ref([]);
    const changePlan = reactive({ isVisible: false, data: {} });
    const inquiry = reactive({ isVisible: false, data: {} });
    const isLoading = ref(false);

    const plan = computed(() => store.getters['plan/plan']);

    const isNewPlan = computed(() => getIsNewPlanById(plan.value.id));

    const fetchAll = async () => {
      const viewableConfigPlans = getViewableConfigPlansById(plan.value.id);
      planItems.value = viewableConfigPlans.viewableConfigPlans;
      lightPlanItems.value = viewableConfigPlans.viewableLightPlanItems;
      const res = await planService.getInquiryList();
      menuItems.value = res.success === true ? res.menus : [];
    };
    const sendInquiryRequest = async payload => {
      if (payload.selected !== 'submit') {
        inquiry.isVisible = false;
        return;
      }
      isLoading.value = true;
      postPottosEvent(54);
      const res = await planService.sendInquiryRequest({
        title: payload.data.title,
      });
      inquiry.isVisible = false;
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const sendChangePlanRequest = async payload => {
      if (payload.selected !== 'submit') {
        changePlan.isVisible = false;
        return;
      }
      isLoading.value = true;
      postPottosEvent(54);
      const res = await planService.sendChangeRequest({
        title: payload.data.name_ja,
      });
      changePlan.isVisible = false;
      isLoading.value = false;
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const getViewableConfigPlansById = planId => {
      switch (planId) {
        case 0:
          // エントリープラン（廃止）
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.free),
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.light500),
              mochicaPlans.find(p => p.id === PLAN_IDS.light700),
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 3:
          // フリープラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.free),
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.light500),
              mochicaPlans.find(p => p.id === PLAN_IDS.light700),
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 1:
          // ライトプラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.light500),
              mochicaPlans.find(p => p.id === PLAN_IDS.light700),
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 4:
          // ライトプラン500
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light500),
              mochicaPlans.find(p => p.id === PLAN_IDS.light700),
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 5:
          // ライトプラン700
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light700),
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 6:
          // ライトプラン900
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [
              mochicaPlans.find(p => p.id === PLAN_IDS.light900),
            ],
          };
        case 2:
          // スタンダードプラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [],
          };
        case 7:
          // データ利用プラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.data),
              mochicaPlans.find(p => p.id === PLAN_IDS.minimum),
              mochicaPlans.find(p => p.id === PLAN_IDS.middle),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [],
          };
        case 8:
          // ミニマムプラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.minimum),
              mochicaPlans.find(p => p.id === PLAN_IDS.middle),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [],
          };
        case 9:
          // ミドルプラン
          return {
            viewableConfigPlans: [
              mochicaPlans.find(p => p.id === PLAN_IDS.middle),
              mochicaPlans.find(p => p.id === PLAN_IDS.standard),
            ],
            viewableLightPlanItems: [],
          };
        default:
          return {
            viewableConfigPlans: [],
            viewableLightPlanItems: [],
          };
      }
    };
    const openModalInquiry = menu => {
      inquiry.data = menu;
      inquiry.isVisible = true;
    };
    const openModalChangePlan = tmpPlan => {
      changePlan.data = tmpPlan;
      changePlan.isVisible = true;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      menuItems,
      planItems,
      lightPlanItems,
      changePlan,
      inquiry,
      isLoading,
      plan,
      isNewPlan,
      getPlanDataById,
      sendInquiryRequest,
      sendChangePlanRequest,
      openModalInquiry,
      openModalChangePlan,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
@include validation_effects2();

.message-plans {
  margin-top: 60px;
}

.message-plans-title {
  margin-bottom: 24px;
  font-size: $font_16;
  font-weight: bold;
  color: $white;
  background-color: #4397d1;
  padding: 15px 30px;
}
.table-wrapper {
  overflow-y: scroll;
  min-height: initial;
  max-height: 250px;

  .table {
    table-layout: fixed;
  }

  tr {
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  td {
    font-weight: bold;

    &.is-alert {
      color: $mochica_color_red;
    }
  }
}
.empty-message-plan {
  text-align: center;
}

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.plan_setting {
  max-width: 1440px;
  margin-bottom: 40px;
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      @media (min-width: ($media_query_tablet)) {
        &.light,
        &.light500,
        &.light700,
        &.light900 {
          width: 50%;
        }
        &.standard {
          width: 50%;
        }
        &.entry,
        &.free {
          width: 33.33%;
        }
        &.data,
        &.minimum,
        &.middle {
          width: 50%;
        }
      }
    }
  }
  .item + .item {
    margin-left: 20px;
  }
}

.plan_title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #777;
}

.inquiry_setting {
  max-width: 1440px;
  .list {
    .item {
      padding: 30px;
      border: 1px solid #ccc;
      border-radius: 5px;
      .menu_block {
        width: 600px;
        padding-right: 20px;
        .title {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .contents {
          margin-bottom: 20px;
          font-size: 1.2rem;
          line-height: 2rem;
          white-space: break-spaces;
        }
      }
      .contact_block {
        .btn {
          background-color: $mochica_color;
        }
        :hover {
          opacity: 0.6;
          transition: all ease 0.3s;
        }
      }
    }
  }
  .item + .item {
    margin-top: 30px;
  }
}

.plan_modal {
  white-space: break-spaces;
}

@media (min-width: ($media_query_sp+1)) and (max-width: ($media_query_tablet)) {
  .plan_setting {
    .list {
      display: block;
      .item + .item {
        margin: 40px 0 0 0;
      }
      .item {
        .plan_block {
          .title_block {
            padding: 10px;
            .title {
              font-size: 1.2rem !important;
            }
          }
        }
        &.entry,
        &.free,
        &.light,
        &.data,
        &.minimum,
        &.middle,
        &.standard {
          width: 100%;
        }
        .content_block {
          padding: 20px !important;
          .content_cell {
            .text {
              height: auto;
            }
          }
          .contact_block {
            width: 100%;
            .btn {
              width: 100% !important;
              line-height: 1 !important;
            }
          }
        }
        .fix_plan {
          .title {
            padding: 10px !important;
            font-size: 1.2rem !important;
          }
          .message {
            font-size: 1.2rem !important;
            font-weight: 100 !important;
          }
        }
      }
    }
  }
  .inquiry_setting {
    .list {
      .item {
        flex-direction: column;
        .menu_block {
          width: auto;
          margin-bottom: 20px;
          padding: 0;
          .title {
            font-size: 1.4rem !important;
          }
          .contents {
            font-size: 1.2rem;
          }
        }
        .contact_block {
          justify-content: flex-start;
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .plan_setting {
    .list {
      display: block;
      .item + .item {
        margin: 30px 0 0 0;
      }
      .item {
        &.entry,
        &.light,
        &.data,
        &.minimum,
        &.middle,
        &.standard {
          width: 100%;
        }
        .plan_block {
          .title_block {
            padding: 10px;
            .title {
              font-size: 1.2rem !important;
            }
          }
        }
        .content_block {
          padding: 20px !important;
          .content_cell {
            .text {
              height: auto;
              word-break: break-all;
            }
          }
          .contact_block {
            width: 100%;
            .btn {
              line-height: 1 !important;
              width: 100% !important;
            }
          }
        }
        .fix_plan {
          .title {
            padding: 10px !important;
            font-size: 1.2rem !important;
          }
          .message {
            font-size: 1.2rem !important;
            font-weight: 100 !important;
          }
        }
      }
    }
  }
  .inquiry_setting {
    .list {
      .item {
        flex-direction: column;
        .menu_block {
          width: auto;
          margin-bottom: 20px;
          padding: 0;
          .title {
            font-size: 1.4rem !important;
          }
          .contents {
            font-size: 1.2rem;
          }
        }
        .contact_block {
          width: 100%;
          justify-content: flex-start;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
